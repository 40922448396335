<script setup>
import {defineProps, inject, onBeforeMount, watch} from "vue";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import ContractService from "@/services/ContractService";
import fileService from "@/services/FileService";
import schoolService from "@/services/SchoolService";
import SchoolService from "@/services/SchoolService";

const props = defineProps({
  showDashboardTab: Boolean,
  showUploadTab: Boolean,
});

const redirectUserToFirstAvailableTab = inject("redirectUserToFirstAvailableTab");

const sampleFileUrl = '/sample.csv';

const router = useRouter();
const store = useStore();

const consent = ref(false);
const selectedFile = ref();
const selectedTerm = ref();
const terms = ref([]);
const schools = ref([]);
const selectedSchool = ref();
const errorMessages = ref({
  contract: '',
  file: '',
});

watch(() => selectedTerm.value, (newVal) => {
  if (newVal) {
    errorMessages.value.contract = '';
  }
});

watch(() => selectedFile.value, (newVal) => {
  if (newVal) {
    errorMessages.value.file = '';
  }
});

const userIsAdmin = ref(false);
const schoolsLoaded = ref(false);
const isPageLoading = ref(true);


const schoolDetail = ref({
  id: '',
  school_name: '',
  aliases: [{ id: '', alias: '' }],
  reporting_streams: [{ id: '', name: '' }],
});

onBeforeMount(async () => {
  isPageLoading.value = true;
  if (!props.showUploadTab) {
    redirectUserToFirstAvailableTab();
    return;
  }

  if (props.showDashboardTab) {
    userIsAdmin.value = true;
  }



  if (userIsAdmin.value) {
    //schoolsLoaded
    console.log('onBeforeMount schoolsLoaded', schoolsLoaded.value);
    await getSchools();
  } else {
    const response = await schoolService.getUserSchoolDetail(router, store);
    if (response.status === "success") {
      schoolDetail.value = response.school;
    } else {
      toast.add({ severity: "error", summary: "Error", detail: response.message });
    }
  }

  isPageLoading.value = false;

  // await getContractTerms();
  // if (terms.value.length > 0) {
  //   selectedTerm.value = terms.value[0];
  // }
});

watch(() => props.showUploadTab, async (newVal) => {
  if (newVal && isPageLoading.value) {
    await getContractTerms();
    isPageLoading.value = false;
  }
});

watch(() => schoolDetail.value, async (newVal) => {
  if (newVal.school_name) {
    if (userIsAdmin.value) {
      terms.value = schools.value.find(school => school.id === newVal.id).contracts.map(contract => ({
        ...contract,
        displayLabel: `${contract.term_start_date_formatted} - ${contract.term_end_date_formatted}`
      }));
    } else {
      await getContractTerms();
    }


    if (terms.value.length > 0) {
      selectedTerm.value = terms.value[0];
    }
  }
});

const showForAdmin = ref(false);
watch(() => selectedSchool.value, async (newVal) => {
  if (newVal) {
    schoolDetail.value = newVal;
    showForAdmin.value = true;
  }
});

async function getContractTerms() {
  const response = await ContractService.listContracts(router, store);
  if (response.status === "success") {
    terms.value = response.contracts.map(contract => ({
      ...contract,
      displayLabel: `${contract.term_start_date_formatted} - ${contract.term_end_date_formatted}`
    }));
  } else {
    toast.add({ severity: "error", summary: "Error", detail: response.message });
  }
}

watch(terms, (newVal) => {
  if (newVal.length > 0) {
    selectedTerm.value = newVal[0];
  }
});

async function getSchools() {
  const getSchoolNamesResponse = await SchoolService.listSchoolDetailWithContracts(router, store);
  if (getSchoolNamesResponse.status === 'success') {
    schoolsLoaded.value = true;
    console.log('onBeforeMount schoolsLoaded', schoolsLoaded.value);
    schools.value = getSchoolNamesResponse.schools.map(school => {
      return {
        id: school.id,
        school_name: school.name,
        aliases: school.aliases,
        reporting_streams: school.reporting_streams,
        contracts: school.contracts,
      }
    });
  } else {
    console.error('Error fetching school names:', getSchoolNamesResponse.message);
  }
}

const onSelect = (event) => {
  selectedFile.value = event.files[0];
};

const fileUpload = ref(null);
const loading = ref(false);
async function uploadFile() {
  loading.value = true;
  if(!selectedTerm.value) {
    errorMessages.value.contract = 'Please select a contract term';
  }

  if (!selectedFile.value) {
    errorMessages.value.file = 'Please select a file to upload';
  }

  if (errorMessages.value.contract || errorMessages.value.file) {
    loading.value = false;
    return;
  }


  const response = await fileService.uploadFile(router, store, selectedFile.value, selectedTerm.value.id);
  if (response.status === "success") {
    toast.add({ severity: "success", summary: "File Upload Successful!", detail: "Your file will be processed and you will be notified via email upon completion. If you don't receive an email, contact your GME Account Manager." });
  } else {
    toast.add({ severity: "error", summary: "File Upload Failed!", detail: "Error encountered. Please try again.", life: 5000 });
  }

  loading.value = false;

  // Clear the file input
  selectedFile.value = null;
  fileUpload.value.clear();
  consent.value = false;

  if (terms.value.length > 0) {
    selectedTerm.value = terms.value[0];
  }

}

const toast = useToast();
</script>

<template>
  <h1 class="text-3xl font-bold py-8 text-primaryBlue">Student CSV File Upload</h1>
<div v-if="props.showUploadTab">
  <Card >
    <template #content>
      <div class="grid grid-cols-12 gap-8">
        <!-- Left Section -->
        <div id="left" class="col-span-5 px-8 py-12 text-wrap">
          <h2 class="text-xl font-bold mb-4">File Upload Guidance:</h2>
          <ul class="list-decimal ml-6 space-y-4">
            <li>Make sure your file format is CSV, not Excel or any other type.</li>
            <li>Sample CSV file is available to download below. Please download and populate the fields accordingly.</li>
            <li>
              Column headers should be the exact same spelling and order as follows:
              Student Identifier, School Name, Reporting Stream Name, Cancel (optional)
            </li>
            <li>Ensure there is only one row per student, per school.</li>
            <li>
              Duplicate records will be stored only once. If student information is changed in subsequent uploads, only the last uploaded data for that student will be saved.
            </li>
            <li>
              CSV field guidelines:
              <ul>
                <li class="py-2 pl-4">1. <span class="font-bold text-black">Plan Key</span>: The plan key your school purchased for the group (e.g. digidoc-gp). </li>
                <li class="py-2 pl-4">2. <span class="font-bold text-black">Leave Row #2 blank.</span></li>
                <li class="py-2 pl-4">3. <span class="font-bold text-black">Student Identifier</span>: Unique identifier used for this student, max length 250 characters. </li>
                <li class="py-2 pl-4">4. <span class="font-bold text-black">School Name</span>: Choose from the available School Names on the upper right part of the screen.
                  If there is no School Name, contact your GME Account Manager.</li>
                <li class="py-2 pl-4">5. <span class="font-bold text-black">Reporting Stream Name</span>: Choose from the available Reporting Streams on the upper right part of the screen.
                  If there is no Reporting Stream, contact your GME Account Manager.</li>
                <li class="py-2 pl-4">6. <span class="font-bold text-black">Cancel (optional)</span>: It can be empty for new student, or 'yes' if you want to cancel a previously submitted student.</li>
              </ul>
            </li>
          </ul>

          <div>
            <img src="../../public/images/SampleDDUpload.png" alt="SampleDDUpload" class="w-full h-auto mt-8" />
          </div>

          <div>
            <h2 class="text-xl font-bold mt-8">Sample File:</h2>
            <div class="flex items-center space-x-4">
              <i class="pi pi-download"/>
              <a :href="sampleFileUrl" download class="text-primaryBlue">Download Sample File</a>
            </div>
          </div>
<!--          <a :href="sampleFileUrl" download class="mt-4">-->
<!--            Download Sample File-->
<!--          </a>-->
        </div>

        <div class="flex justify-center items-center col-span-1">
          <Divider layout="vertical" type="solid" class="h-full w-[10px]" />
        </div>

        <div id="right" class="col-span-5 px-8 py-12 text-wrap">
          <div id="school-detail" class="text-sm mb-8">
            <h1 v-if="schoolDetail && schoolDetail.school_name" class="text-2xl font-bold text-primaryBlue mb-2">{{schoolDetail?.school_name}}</h1>
            <div v-if="schoolDetail?.aliases?.length > 0 && schoolDetail.aliases[0].id" id="school-aliases" class="my-2 flex space-x-2">
              <div class="w-[150px] h-7 flex items-center">School Names</div>
              <div id="school-detail-aliases">
                <Tag v-for="alias in schoolDetail.aliases" :key="alias.id" :severity="'secondary'" :value="alias.alias" class="mx-1"></Tag>
              </div>
            </div>
            <div v-if="schoolDetail?.reporting_streams?.length > 0 && schoolDetail.reporting_streams[0].id" id="school-reporting-streams" class="my-2 flex space-x-2">
              <div class="w-[150px] h-7 flex items-center">Reporting Streams</div>
              <div id="school-detail-aliases">
                <Tag v-for="reportingStream in schoolDetail.reporting_streams" :key="reportingStream.id" :severity="'secondary'" :value="reportingStream.name" class="mx-1"></Tag>
              </div>
            </div>
          </div>

          <div v-if="userIsAdmin" id="school-selector" class="w-full pb-4">
            <Skeleton v-if="!schoolsLoaded" class="pb-2" height="2rem" width="10rem"></Skeleton>
            <p v-else class="pb-2">Upload For School: </p>
            <Skeleton v-if="!schoolsLoaded" width="100%" height="3rem" />
            <Select v-if="schoolsLoaded && schools.length > 0" v-model="selectedSchool" :options="schools" optionLabel="school_name" placeholder="Select a School" class="w-full" />
          </div>

          <div v-if="!userIsAdmin && isPageLoading" id="term-selector-skeleton" class="w-full animate-pulse">
            <p class="pb-2 bg-gray-300 h-6 w-1/3 mb-2"></p>
            <div class="h-10 bg-gray-200 rounded mb-4"></div>
            <div class="h-6 bg-gray-200 rounded"></div>
          </div>

          <div v-if="(!isPageLoading && !userIsAdmin) || (userIsAdmin&&showForAdmin)" id="term-selector" class="w-full">
            <p class="pb-2">Contract Term:</p>
            <Select v-if="terms.length>0" v-model="selectedTerm" :options="terms" optionLabel="displayLabel" placeholder="Select a Term" class="w-full" />
            <Message severity="error" v-if="terms.length===0 && userIsAdmin"> No Contracts Available </Message>
            <Message severity="error" v-if="terms.length===0 && !userIsAdmin"> Contract period not set. Please contact your GME Account Manager. </Message>
            <Message severity="error" v-if="errorMessages.contract"> {{errorMessages.contract}} </Message>
          </div>

          <div v-if="!userIsAdmin || (userIsAdmin&&showForAdmin)" id="file-selector">
            <p class="pt-8 pb-2">Select File:</p>
            <div id="file-selector" class="card border-2 rounded-lg bg-transparent border-grey py-2 px-2 ">
              <Toast />
              <FileUpload
                  ref="fileUpload"
                  mode="basic"
                  name="fileToUpload"
                  accept=".csv"
                  invalidFileTypeMessage="Invalid file type. Please upload a CSV file."
                  :maxFileSize="5000000"
                  @select="onSelect"
                  :customUpload="true"
                  chooseLabel="Choose File"
                  class="justify-self-start bg-green-500 border-none h-[40px]"
              />
              <Message severity="error" v-if="errorMessages.file"> {{errorMessages.file}} </Message>
            </div>
          </div>


          <div v-if="!userIsAdmin || (userIsAdmin&&showForAdmin)" id="consent" class="flex items-start py-8">
            <div class="card flex">
              <Checkbox v-model="consent" :binary="true" />
            </div>
            <div class="flex-1 ml-4">
              <label for="consent" class="">
                I hereby declare, on behalf of my organization, that we have
                obtained the consent of our registrants to provide their personal information to guard.me International
                Insurance, for the purposes of enrolling them in the Digital Doctor program.
              </label>
            </div>
          </div>

          <Button v-if="!userIsAdmin || (userIsAdmin&&showForAdmin)" label="Upload CSV" @click="uploadFile" :disabled="!consent || !selectedFile || !selectedTerm" :loading="loading" severity="secondary" class="bg-primaryBlue text-white" />
        </div>


      </div>
    </template>
  </Card>



</div>
</template>

<style scoped>
.p-fileupload {
  justify-content: start;
}
</style>