import axios from "axios";
import tokenService from "@/services/TokenService";

const schoolService = {
    countSchools: async (router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/schools/count', config)
            .then((response) => {
                return {
                    status: 'success',
                    count: response.data.count
                };
            }).catch((error) => {
                console.error('Failed to call countSchools:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    getSchoolNames: async (router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/schools', config)
            .then(response => {
                return {
                    status: 'success',
                    schools: response.data.schools
                };
            })
            .catch(error => {
                console.error('Error loading schools:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    getSchoolIdentifierLengths: async (router, store, schoolName) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/schools/lengths', {
            schoolName: schoolName,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    lengths: response.data.lengths
                };
            })
            .catch(error => {
                console.error('Error loading lengths:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    listSchoolDetails: async (router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/schools/details', config)
            .then(response => {
                return {
                    status: 'success',
                    schools: response.data,
                };
            })
            .catch(error => {
                console.error('Error loading schools:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    listSchoolDetailWithContracts: async (router, store) => {
        ///details-with-contract
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/schools/details-with-contract', config)
            .then(response => {
                console.log(response)
                return {
                    status: 'success',
                    schools: response.data,
                };
            })
            .catch(error => {
                console.error('Error loading schools:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    createReportingStream: async (router, store, schoolId, reportingStream) => {
        if (!schoolService.checkIfEmpty(reportingStream)) {
            return {
                status: 'error',
                message: 'Reporting Stream name cannot be empty'
            };
        }

        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/reportingStreams', {
            name: reportingStream,
            school_id: schoolId,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    reporting_stream: response.data,
                };
            })
            .catch(error => {
                console.error('Error creating reporting stream:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.error
                };
            });
    },
    editReportingStream: async (router, store, reportingStreamId, reportingStreamName) => {
        if (!schoolService.checkIfEmpty(reportingStreamName)) {
            return {
                status: 'error',
                message: 'Reporting Stream name cannot be empty'
            };
        }

        const config = tokenService.getConfig(router, store);
        return await axios.put(process.env.VUE_APP_API_BASE_URL + '/api/reportingStreams/' + reportingStreamId, {
            name: reportingStreamName,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    message: response.data.message
                };
            })
            .catch(error => {
                console.error('Error editing reporting stream:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    createAlias: async (router, store, schoolId, alias) => {
        if (!schoolService.checkIfEmpty(alias)) {
            return {
                status: 'error',
                message: 'Alias name cannot be empty'
            };
        }

        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/aliases', {
            name: alias,
            school_id: schoolId,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    id: response.data.id,
                    alias: response.data.alias
                };
            })
            .catch(error => {
                console.error('Error creating alias:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.error
                };
            });
    },
    editAlias: async (router, store, aliasId, aliasName) => {
        if (!schoolService.checkIfEmpty(aliasName)) {
            return {
                status: 'error',
                message: 'Alias name cannot be empty'
            };
        }

        const config = tokenService.getConfig(router, store);
        return await axios.put(process.env.VUE_APP_API_BASE_URL + '/api/aliases/' + aliasId, {
            alias: aliasName,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    id: response.data.id,
                    message: response.data.message
                };
            })
            .catch(error => {
                console.error('Error editing alias:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.error
                };
            });
    },
    createContract: async (router, store, schoolId, contractNumber, termStartDate, termEndDate) => {
        if (!schoolService.checkIfEmpty(contractNumber)) {
            return {
                status: 'error',
                message: 'Contract number cannot be empty'
            };
        }

        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/contracts', {
            contract_number: contractNumber,
            term_start_date: termStartDate,
            term_end_date: termEndDate,
            school_id: schoolId,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    contract: response.data,
                };
            })
            .catch(error => {
                console.error('Error creating contract:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    updateContract: async (router, store, schoolId, contractId, termStartDate, termEndDate) => {
        const config = tokenService.getConfig(router, store);
        return await axios.put(process.env.VUE_APP_API_BASE_URL + '/api/contracts/' + contractId, {
            term_start_date: termStartDate,
            term_end_date: termEndDate,
            school_id: schoolId,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    contract: response.data,
                };
            })
            .catch(error => {
                console.error('Error updating contract:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    addNewSchoolWithAssociatedData: async (router, store, schoolName, reportingStreams, aliases, contracts) => {
        if (!schoolService.checkIfEmpty(schoolName)) {
            return {
                status: 'error',
                message: 'School name cannot be empty'
            };
        }

        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/schools/full', {
            name: schoolName,
            reporting_streams: reportingStreams,
            aliases: aliases,
            contract_number: contracts?.contract_number,
            term_start_date: contracts?.term_start_date,
            term_end_date: contracts?.term_end_date,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    school: response.data.school,
                };
            })
            .catch(error => {
                console.error('Error creating school:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    checkIfEmpty: (name) => {
        return name.trim() !== '';
    },
    getUserSchoolDetail: async (router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/schools/user-school-detail', config)
            .then(response => {
                return {
                    status: 'success',
                    school: response.data
                };
            })
            .catch(error => {
                console.error('Error loading school:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    getSchoolActivityLogs: async (router, store, schoolId, offset) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/schools/' + schoolId + '/activity-log?offset=' + offset, config)
            .then(response => {
                console.log(response);
                return {
                    status: 'success',
                    logs: response.data
                };
            })
            .catch(error => {
                console.error('Error loading school activity logs:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message || 'An error occurred'
                };
            });
    },
};

export default schoolService;