<template>
  <main>
    <UnauthenticatedLayout>
      <Toast position="top-right" group="tr"/>
      <div id="login-card-container" class="m-0 mx-auto max-w-[420px] grid justify-center items-center text-center">
        <Card id="login-card" class="bg-white bg-opacity-95 pb-8">
          <template #content>
            <img id="logo" class="mx-auto w-4/5" alt="user header" src="/images/GM_Digital_Doctor_Logo_RGB_150dpi.png"/>
            <LoginCard ref="loginCard" v-if="step === steps.login" :loginStatus="this.loginStatus"
                       @trigger-login="attemptLogin" @forget-password="forgetPassword"/>
            <MfaSelectionCard v-else-if="step === steps.mfaSelection" :phoneNumber="this.phoneNumber"
                              :email="this.email" @cancel-method="cancelMfaMethodSelection" @confirm-method="login"/>
            <MfaCard v-else-if="step === steps.mfaVerification" :email="this.email" :mfaStatus="mfaStatus"
                     @resend-code="resendCode" @cancel-mfa="cancelMfa" @verify-code="verifyMfaCode"/>
            <SetPasswordCard v-else-if="step === steps.setPassword" :setPasswordStatus="this.setPasswordStatus"
                             @cancel-set-password="cancelSetPassword" @login-with-temp-password="loginWithTempPassword"
                             @show-toast-message="showToastMessage"/>
            <ForgetPasswordCard v-else-if="step === steps.forgetPassword"
                                :sendResetCodeStatus="this.sendResetCodeStatus"
                                @request-reset-password-code="requestResetPasswordCode"
                                @cancel-forget-password="cancelForgetPassword"/>
            <ResetPasswordWithCodeCard v-else-if="step === steps.resetPasswordWithCode"
                                       :resetPasswordStatus="this.resetPasswordStatus"
                                       :sendResetCodeStatus="this.sendResetCodeStatus" :email="this.email"
                                       @cancel-reset="cancelForgetPasswordReset" @show-toast-message="showToastMessage"
                                       @set-new-password="setNewPassword" @resend-code="requestResetPasswordCode"/>
          </template>
        </Card>
      </div>
    </UnauthenticatedLayout>
  </main>
</template>

<script>
import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import LoginCard from "@/components/LoginCard.vue";
import MfaCard from "@/components/MfaCard.vue";
import SetPasswordCard from "@/components/desprecated/SetPasswordCard.vue";
import AuthenticationService from "@/services/AuthenticationService";
import {useToast} from 'primevue/usetoast';
import ForgetPasswordCard from "@/components/ForgetPasswordCard.vue";
import ResetPasswordWithCodeCard from "@/components/ResetPasswordWithCodeCard.vue";
import MfaSelectionCard from "@/components/MfaSelectionCard.vue";
import tokenService from "@/services/TokenService";


const steps = {
  login: 'login',
  mfaSelection: 'mfa-selection',
  mfaVerification: 'mfa-verification',
  setPassword: 'set-password',
  forgetPassword: 'forget-password',
  resetPasswordWithCode: 'reset-password-with-code'
};

export default {
  beforeCreate() {
    const token = this.$store.state.userToken;
    if (token && !tokenService.isTokenExpired(token)) {
      this.$router.push('/dashboard');
    }
  },
  components: {
    MfaSelectionCard,
    ForgetPasswordCard,
    LoginCard,
    MfaCard,
    ResetPasswordWithCodeCard,
    SetPasswordCard,
    UnauthenticatedLayout
  },
  data() {
    return {
      email: '',
      phoneNumber: '',
      loginStatus: '',
      mfaStatus: '',
      password: '',
      resetPasswordStatus: '',
      sendResetCodeStatus: '',
      setPasswordStatus: '',
      step: steps.login,
      steps,
      showTokenExpiringMessage: '',
    };
  },
  setup() {
    const toast = useToast();

    const showToastMessage = (severity, summary, detail) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tr',
        life: 3000
      });
    };

    const showNoExpirationToastMessage = (severity, summary, detail) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tr',
      });
    };

    return {showToastMessage, showNoExpirationToastMessage};
  },
  mounted() {
    console.log("mounted login page" + this.$store.state.showTokenExpiringMessage)
    this.showTokenExpiringMessage = this.$store.state.showTokenExpiringMessage;
    if (this.showTokenExpiringMessage) {
      this.showToastMessage(
          'info',
          'Session Expired',
          'Your session expired. Please login to use the system.'
      );

      this.$store.commit('setShowTokenExpiringMessage', null);
    }

    const token = this.$store.state.userToken;
    if (token === "disabled") {
      this.showNoExpirationToastMessage(
          'error',
          'User Access Disabled',
          'Your access to the system has been disabled by the admin.'
      );

      this.$store.commit('setUserToken', '');
    }

    if (token === "verified") {
      this.showNoExpirationToastMessage(
          'success',
          'Super Admin Account Verified',
          'Your email has been verified. Please login to use the system and change your password in Profile page.'
      );

      this.$store.commit('setUserToken', '');
    }
  },
  methods: {
    async attemptLogin(email, password) {
      this.email = email;
      this.password = password;

      // const loginCheckResponse = await AuthenticationService.loginCheck(email, password);
      // console.log(loginCheckResponse);
      //
      // if (loginCheckResponse.status === "error") {
      //   this.showToastMessage(
      //       'error',
      //       'Login Error',
      //       loginCheckResponse.message
      //   );
      //
      //   this.loginStatus = 'error';
      //   return;
      // }

      // Check if new password is required
      const resetPasswordRequiredResponse = await AuthenticationService.checkIfResetPasswordRequired(email, password);
      console.log(resetPasswordRequiredResponse);
      if (resetPasswordRequiredResponse.status === "new_password_required") {
        this.step = steps.setPassword;
        return;
      }

      if (resetPasswordRequiredResponse.status === "incorrect_username_or_password") {
        this.showToastMessage(
            'error',
            'Login Error',
            'Incorrect username or password.'
        );

        this.loginStatus = 'error';
        return;
      }

      if (resetPasswordRequiredResponse.status === "error") {
        this.showToastMessage(
            'error',
            'Login Error',
            resetPasswordRequiredResponse.message
        );

        this.loginStatus = 'error';
        return;
      }

      //get user number getUserNumber
      const getNumberResponse = await AuthenticationService.getUserNumber(email);
      console.log(getNumberResponse);
      if (getNumberResponse.status === "success") {
        this.phoneNumber = getNumberResponse.userNumber;
      } else {
        this.phoneNumber = '';
      }

      //todo:get user phone number
      this.step = steps.mfaSelection;
    },
    async login(authChallengeType) {
      this.loginStatus = '';
      const loginResponse = await AuthenticationService.login(this.$store, this.email, this.password, authChallengeType);
      this.loginStatus = loginResponse.status;
      if (this.loginStatus === "new_password_required") {
        console.log('new_password_required');
        this.step = steps.setPassword;
      } else if (this.loginStatus === "error") {
        this.showToastMessage(
            'error',
            'Login Error',
            loginResponse.message
        );
      } else if (this.loginStatus === "success") {
        this.step = steps.mfaVerification;
        this.showNoExpirationToastMessage(
            'success',
            'Verification Code Sent',
            'Please enter the verification code sent to your selected method. Code is only valid for 3 minutes.'
        );
      }
    },
    forgetPassword() {
      this.step = steps.forgetPassword;
    },
    cancelForgetPassword() {
      this.step = steps.login;
    },
    async verifyMfaCode(email, mfaCode) {
      this.mfaStatus = '';
      const mfaResponse = await AuthenticationService.verifyMFACode(this.$router, this.$store, email, mfaCode, this.$store.state.session);
      this.mfaStatus = mfaResponse.status;
      if (this.mfaStatus === "success") {
        this.$router.push('/dashboard');
      } else {
        this.showToastMessage(
            'error',
            'MFA Error',
            'Failed to verify MFA code. Please check the code and try again.'
        );
      }
    },
    async resendCode() {
      this.mfaStatus = 'pending';
      await AuthenticationService.login(this.$store, this.email, this.password);
      this.showNoExpirationToastMessage(
          'info',
          'Verification Code Resent',
          'A new verification code has been sent to your email. Code is only valid for 3 minutes.'
      );
      this.mfaStatus = '';
    },
    cancelMfa() {
      this.step = steps.login;
    },
    cancelSetPassword() {
      this.step = steps.login;
    },
    cancelForgetPasswordReset() {
      this.step = steps.forgetPassword;
    },
    async requestResetPasswordCode(email) {
      this.sendResetCodeStatus = 'pending';
      const requestResetPasswordCodeResponse = await AuthenticationService.requestResetPasswordCode(email);
      this.sendResetCodeStatus = requestResetPasswordCodeResponse.status;
      if (requestResetPasswordCodeResponse.status === "success") {
        this.step = steps.resetPasswordWithCode;
        this.email = email;
        this.showToastMessage(
            'success',
            'Reset Password Code Sent',
            'A reset password code has been sent to your email.'
        );
      } else {
        this.showToastMessage(
            'error',
            'Reset Password Error',
            requestResetPasswordCodeResponse.message
        );
      }
    },
    async setNewPassword(code, newPassword) {
      this.resetPasswordStatus = '';
      const resetPasswordResponse = await AuthenticationService.forgetPasswordConfirm(this.email, code, newPassword);
      this.resetPasswordStatus = resetPasswordResponse.status;
      if (this.resetPasswordStatus === "success") {
        this.step = steps.login;
        this.showToastMessage(
            'success',
            'Reset Password Success',
            'Password reset successfully. Please login with your new password.'
        );
      } else {
        this.showToastMessage(
            'error',
            'Reset Password Error',
            resetPasswordResponse.message
        );
      }
    },
    async loginWithTempPassword(newPassword) {
      this.setPasswordStatus = '';
      const loginWithTempPasswordResponse = await AuthenticationService.loginWithTempPassword(this.$router, this.$store, this.email, this.password, newPassword, newPassword);
      this.setPasswordStatus = loginWithTempPasswordResponse.status;
      if (this.setPasswordStatus === "success") {
        this.$router.push('/dashboard');
      } else {
        this.showToastMessage(
            'error',
            'Set Password Error',
            'Failed to set new password. Please try again.'
        );
      }
    },
    cancelMfaMethodSelection() {
      this.step = steps.login;
    },
  },
};
</script>

<style scoped>

</style>