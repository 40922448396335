import axios from "axios";
import tokenService from "@/services/TokenService";
import AccountService from "@/services/AccountService";


const accountService = {
    countAccounts: async (router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/accounts/count', config)
            .then((response) => {
                return {
                    status: 'success',
                    count: response.data.count
                };
            }).catch((error) => {
                console.error('Failed to call countAccounts:', error.response || error);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    listAccounts: async (router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/accounts', config)
            .then(response => {
                return {
                    status: 'success',
                    accounts: response.data.accounts
                };
            })
            .catch(error => {
                console.error('Error loading users:', error.response || error);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    createNewAccount: async (newAccount, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/cognito/admin/users',
            {
                email: newAccount.email,
                name: newAccount.name,
                phone_number: newAccount.phone_number.replace(/\s/g, ''),
            }, config)
            .then(response => {
                console.log('Account created:', response.data)
                return {
                    status: 'success',
                    account: response.data.account  //fixme
                };
            })
            .catch(error => {
                console.error('Error creating account:', error?.response?.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    authorizeUserRole: async (newAccount, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/permissions/authorize',
            {
                name: newAccount.name,
                email: newAccount.email,
                role: newAccount.role.name, //user name not value which is matching the backend
                school_name: newAccount.school_name.name,
            }, config)
            .then(() => {
                console.log('User authorized');
                return {
                    status: 'success',
                    message: 'User authorized'
                };
            })
            .catch(error => {
                console.error('Error authorizing user:', error?.response?.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    saveNewUser: async (newUser, router, store) => {
        //step 1: create account
        const createUserResponse = await accountService.createNewAccount(newUser, router, store);
        if (createUserResponse.status === 'error') {
            return createUserResponse;
        }

        //step 2: authorize user
        return await accountService.authorizeUserRole(newUser, router, store);
    },
    editCognitoUser: async (config, updatedUser) => {
        return await axios.put(process.env.VUE_APP_API_BASE_URL + '/api/cognito/admin/users', {
            name: updatedUser.name,
            email: updatedUser.email,
            phone_number: updatedUser.phone_number,
        }, config)
            .then(response => {
                console.log('User updated:', response.data)
                return {
                    status: 'success',
                    user: response.data.user
                };
            })
            .catch(error => {
                console.error('Error updating user:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    editSchool: async (config, user_id, school_name) => {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/accounts/change_school',
            {
                user_id: user_id,
                school_name: school_name,
            }
            , config)
            .then(() => {
                console.log('School changed');
                return {
                    status: 'success',
                    message: 'School changed'
                };
            })
            .catch(error => {
                console.error('Error changing school:', error?.response?.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    editRole: async (config, user_id, role) => {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/accounts/change_role',
            {
                user_id: user_id,
                role_name: role,
            }
            , config)
            .then(() => {
                console.log('Role changed');
                return {
                    status: 'success',
                    message: 'Role changed'
                };
            })
            .catch(error => {
                console.error('Error changing role:', error?.response?.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    editUser: async (user_id, changes, updatedUser, router, store) => {
        if (changes.length === 0) {
            return {
                status: 'success',
                message: 'No changes to save'
            };
        }

        //remove spaces from phone number
        updatedUser.phone_number = updatedUser.phone_number.replace(/\s/g, '');

        const config = tokenService.getConfig(router, store);

        let cognitoResponse, roleResponse, schoolResponse;

        if (changes.includes('cognito')) {
            cognitoResponse = await AccountService.editCognitoUser(config, updatedUser);
            if (cognitoResponse.status === 'error') {
                return cognitoResponse;
            }
        }

        if (changes.includes('role')) {
            roleResponse = await AccountService.editRole(config, user_id, updatedUser.role);
            if (roleResponse.status === 'error') {
                return roleResponse;
            }
        }

        if (changes.includes('school_name')) {
            schoolResponse = await AccountService.editSchool(config, user_id, updatedUser.school_name);
            if (schoolResponse.status === 'error') {
                return schoolResponse;
            }
        }

        return {
            status: 'success',
            message: 'User updated'
        };
    },
    enableUser: async (email, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/cognito/admin/users/access/enable', {
            email: email,
        }, config)
            .then(response => {
                console.log('User enabled:', response.data)
                return {
                    status: 'success',
                    user: response.data.user
                };
            })
            .catch(error => {
                console.error('Error enabling user:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    disableUser: async (email, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/cognito/admin/users/access/disable', {
            email: email,
        }, config)
            .then(response => {
                console.log('User disabled:', response.data)
                return {
                    status: 'success',
                    user: response.data.user
                };
            })
            .catch(error => {
                console.error('Error disabling user:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    resendTempPassword: async (email, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/cognito/admin/users/resend-temporary-password', {
            email: email,
        }, config)
            .then(response => {
                return {
                    status: 'success',
                    user: response.data.user
                };
            })
            .catch(error => {
                console.error('Error resending temp password:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    getUserNote: async (note_Id, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/notes/' + note_Id,
            config)
            .then(response => {
                console.log('getUserNote:', response.data);
                return {
                    status: 'success',
                    notes: response.data
                };
            })
            .catch(error => {
                console.error('Error loading notes:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    updateNote: async (note_Id, noteContent, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/notes/' + note_Id,
            {
                title: 'School User Note',
                content: noteContent,
            }, config)
            .then(response => {
                console.log('Note updated:', response.data);
                return {
                    status: 'success',
                    notes: response.data
                };
            })
            .catch(error => {
                console.error('Error updating notes:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    createNewNote: async (userId, noteContent, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/notes',
            {
                parent_type: 'user_detail',
                parent_id: userId,
                title: 'School User Note',
                content: noteContent,
            }, config)
            .then(response => {
                console.log('Note saved:', response.data);
                return {
                    status: 'success',
                    notes: response.data
                };
            })
            .catch(error => {
                console.error('Error saving notes:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    saveUserNote: async (userId, noteId, noteContent, router, store) => {
        if (noteId) {
            return await AccountService.updateNote(noteId, noteContent, router, store);
        } else {
            return await AccountService.createNewNote(userId, noteContent, router, store);
        }
    },
    canCreateAdmin: async (router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/permissions/can-create-admin', config)
            .then(response => {
                return {
                    status: 'success',
                    canCreateAdmin: response.data.authorized
                };
            })
            .catch(error => {
                console.error('Error checking canCreateAdmin:', error.response.data);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
    logActivity: async (userid, event, old, attributes, description, router, store) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/accounts/log-activity',
            {
                user_id: userid,
                event: event,
                old: old,
                attributes: attributes,
                description: description,
            }, config)
            .then(() => {
                return {
                    status: 'success',
                };
            })
            .catch(error => {
                console.error('Error logging activity:', error.response.data);
                return {
                    status: 'error',
                    message: error.response?.data?.message
                };
            });
    },
    getAccountActivityLogs: async (router, store, userId, offset) => {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/accounts/' + userId + '/activity-log?offset=' + offset, config)
            .then(response => {
                console.log(response);
                return {
                    status: 'success',
                    logs: response.data
                };
            })
            .catch(error => {
                console.error('Error loading school activity logs:', error.response || error);
                return {
                    status: 'error',
                    message: error.response?.data?.message || 'An error occurred'
                };
            });
    },
};

export default accountService;